aside, form {
    font-size: 0.9em;
    font-style: inherit;
    display: inline-block;
    max-width: 80%;
    padding: .5em 1em;
    box-sizing: content-box;
    border-radius: 0.25em;
    text-align: left;
    border: 1px solid #bbb;
    margin: 0 auto;
    background-color: rgba(256,256,256,0.25);

    &[focus], &[warn] { background-color: rgba(255,249,178,0.25); }

    &.nobox, &[nobox] {
        border: 0;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        font-size: inherit;
        text-align: inherit;
    }

    p {
        margin: 0.75rem 0;
    }

    h3, h4, h5, h6 {
        font-size: 1.25em;
        font-family: inherit;
        font-weight: 900;
        font-variant: inherit;
        margin: 0.75rem 0;
    }

    nav {
        display: block;
        text-align: center;

        p {
            display: inline-block;
        }
    }
}

ul#markdown-toc {
    list-style-type: none;
    margin: 0;
    padding-inline-start: 0;

    > li {
        list-style: none;
        padding-left: 0;
        font-weight: bold;

        > a {
            color: #666666;
        }
    }

    ul {
        padding-inline-start: 0.25rem;

        li {
            list-style: none;
            padding-left: 0;

            > a, > a i {
                font-size: 0.9em;
                font-weight: normal;
                color: #807040;
            }
        }
    }

    *[surtitle], *[subtitle] {
        display: none;
    }
}

main .sidebar, main *[sidebar] {
    position: sticky;
    float: right;
    width: 25vw;
    max-width: 10rem;
    max-height: 90vh;
    overflow-y: scroll;
    top: 2rem;
    margin-top: -5em;
    margin-right: Max(-12rem, -31vw);
    margin-left: -12rem;
    background-color: rgba(230, 230, 230, 0.80);
    z-index: 1000;
}
@keyframes slidein {
    from { margin-right: Max(-12rem, -31vw) }
    to { margin-right: -12vw; }
}
@keyframes slideout {
    from { margin-right: -12vw; }
    to { margin-right: Max(-12rem, -31vw) }
}
@media only screen and (max-width: 1300px) {
    main .sidebar, main *[sidebar] {
        &:hover, &:focus-within { animation: slidein .2s; margin-right: -12vw; }
        &:not(:hover, :focus-within) { animation: slideout .2s; }
    }
}

*[shadow], .shadow {
    display: inline-block;
    background-image: radial-gradient(#666666 15%, rgba(0,0,0,0) 66%);
    filter: drop-shadow(1px 1px 5px #666666);
}

button, i, img, input, *[btn], *[cta], *[submit], .btn, .cta, .submit {
    &[sm] {
        max-width: 25%;
        max-height: 15rem;
    }
    &[md] {
        max-width: 50%;
        max-height: 20rem;
    }
    &[lg] {
        max-width: 75%;
        max-height: 25rem;
    }
}

h1, h2, h3, h4, h5, h6 {
    small[subtitle], small[surtitle] { display: block; }
}

h4, h5, h6 {
    small[subtitle], small[surtitle] {
        font-family: 'Halant', Palatino, Book Antiqua, serif;
        font-variant: normal;
    }
}

*[left] img, .left img{
    float: left;
    max-width: 35%;
    padding-right: 1em;
}
*[right] img, .right img{
    float: right;
    max-width: 35%;
    padding-left: 1em;
}
*[center], .center {
    text-align: center;
}

img {
    display: inline-block;

    &[corner], &.corner {
        border-radius: 1rem;
        text-shadow: 1px 1px 0.5rem #666666;
        -webkit-box-shadow: 1px 1px 0.5rem #666666;
        -moz-box-shadow: 1px 1px 0.5rem #666666;
        box-shadow: 1px 1px 0.5rem #666666;
    }

    &.half {
        max-width: 50%;
    }

    &.right {
        float: right;
        max-width: 35%;
        margin-left: 1em;
        margin-bottom: 1em;
    }

    &.left {
        float: left;
        max-width: 35%;
        margin-right: 1em;
        margin-bottom: 1em;
    }
}

*[clear], .clear {
    clear: both;
}

main {
    button, *[btn], *[cta], *[submit], .btn, .cta, .submit {
        background: #3498db;
        background-image: -webkit-linear-gradient(top, #3498db, #33008b);
        background-image: -moz-linear-gradient(top, #3498db, #33008b);
        background-image: -ms-linear-gradient(top, #3498db, #33008b);
        background-image: -o-linear-gradient(top, #3498db, #33008b);
        background-image: linear-gradient(to bottom, #3498db, #33008b);
        -webkit-border-radius: 18;
        -moz-border-radius: 18;
        border-radius: 18px;
        border-width: 0;
        text-shadow: 1px 1px 3px #666666;
        -webkit-box-shadow: 1px 1px 3px #666666;
        -moz-box-shadow: 1px 1px 3px #666666;
        box-shadow: 1px 1px 3px #666666;
        display: inline-block;
        margin: 0.5rem auto;
        font-size: inherit;
        padding: 0.6em 1em;
        color: #eeeeee !important;
        text-decoration: none;
        font: inherit;
        cursor: pointer;

        &[sm], &.small {
            font-size: 12px;
            padding-top: 0.8em;
        }

        &[md], &.medium {
            font-size: 16px;
        }

        &[lg], &.large {
            font-size: 20px;
        }

        &::before, i::before {
            margin-right: 0.3em !important;
            opacity: .9 !important;
            filter: invert(1);
        }

        &:hover {
            background: #3cb0fd;
            background-image: -webkit-linear-gradient(top, #3cb0fd, #51259e);
            background-image: -moz-linear-gradient(top, #3cb0fd, #51259e);
            background-image: -ms-linear-gradient(top, #3cb0fd, #51259e);
            background-image: -o-linear-gradient(top, #3cb0fd, #51259e);
            background-image: linear-gradient(to bottom, #3cb0fd, #51259e);
            text-decoration: none;
        }

        &:active {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
}
