
i::before, time::before, main .cta::before, main *[cta]::before {
    // Default for others is 'info'
    background-image: url('../img/i/info.svg');
}

main a[href^="http://"]:not(.noicon)::before, main a[href^="https://"]:not(.noicon)::before {
    // Default for Links is ext. Don't allow inner <i> icon inside a link.
    background-image: url('../img/i/ext.svg');
}

i, time, main .cta, main *[cta], main a[href^="http://"]:not(.noicon), main a[href^="https://"]:not(.noicon),
main a[href^="/email-list"]:not(.noicon) {
    font-style: inherit;
    font-weight: 900;
    display: inline-block;

    // Default for others is 'info'
    &::before {
        background-image: url('../img/i/info.svg');
        content: '\00a0'; // non-breaking space
        display: inline-block;
        margin: -0.2em 0.15em 0.1em 0em;
        vertical-align: middle;
        width: 1.1em;
        height: 1.1em;
        background-size: 100% 100%;
        opacity: .5;
        transition: all .25s;
    }

    &:empty, &[icononly] {
        display: inline-block;
        vertical-align: top;
        margin-top: -0.15em;
        font-size: 1.2em;
        max-width: 1.185em;
        overflow: hidden;
        white-space: nowrap;
    }

    &[fb], &[facebook], &.fb { &::before { background-image: url('../img/i/brand/facebook.svg'); } }
    &[gh], &[github], &.gh { &::before { background-image: url('../img/i/brand/github.svg'); } }
    &[gram], &[instagram], &.gram { &::before { background-image: url('../img/i/brand/instagram.svg'); } }
    &[igg], &[indiegogo], &.igg { &::before { background-image: url('../img/i/brand/indiegogo.svg'); } }
    &[itchio], &[itch], &.itch { &::before { background-image: url('../img/i/brand/itch-io.svg'); } }
    &[masto], &.masto { &::before { background-image: url('../img/i/brand/mastodon.svg'); } }
    &[patreon], &.patreon { &::before { background-image: url('../img/i/brand/patreon.svg'); } }
    &[paypal], &.paypal { &::before { background-image: url('../img/i/brand/paypal.svg'); } }
    &[spotify], &.spotify { &::before { background-image: url('../img/i/brand/spotify.svg'); } }
    &[tiktok], &.tiktok { &::before { background-image: url('../img/i/brand/tiktok.svg'); } }
    &[tumblr], &.tumblr { &::before { background-image: url('../img/i/brand/tumblr.svg'); } }
    &[twitch], &.twitch { &::before { background-image: url('../img/i/brand/twitch.svg'); } }
    &[twitter], &.twitter { &::before { background-image: url('../img/i/brand/twitter.svg'); } }
    &[wp], &.wp { &::before { background-image: url('../img/i/brand/wordpress.svg'); } }
    &[yt], &[youtube], &.yt { &::before { background-image: url('../img/i/brand/youtube.svg'); } }
    &[redbubble], &.redbubble {&::before { background-image: url('../img/i/brand/redbubble.svg'); }}

    &[backstage], &.backstage { &::before { background-image: url('../img/i/brand/backstage.svg'); } }
    // We don't use an actual BPT logo because their logos aren't usable as a flat square icon at small sizes.
    &[bpt], &[tickets], &.tickets { &::before { background-image: url('../img/i/ticket.svg'); } }
    &[carrd], &.carrd { &::before { background-image: url('../img/i/brand/carrd.svg'); } }
    &[linktree], &.linktree { &::before { background-image: url('../img/i/brand/linktree.svg'); } }
    &[linkedin], &.linkedin { &::before { background-image: url('../img/i/brand/linkedin.svg'); } }
    // We also use a simple upside-down 5-pointed star graphic of Nebula's logo because theirs isn't a flat icon.
    &[nebula], &.nebula { &::before { background-image: url('../img/i/brand/nebula.svg'); } }

    &[blm], &[raised-fist], &.blm { &::before { background-image: url('../img/i/raised-fist.svg'); } }
    &[cal], &.cal { &::before { background-image: url('../img/i/cal.svg'); } }
    &[donate], &.donate { &::before { background-image: url('../img/i/donate.svg'); }}
    &[cc], &.cc { &::before { background-image: url('../img/i/brand/cc.svg'); } }
    &[email], &.email { &::before { background-image: url('../img/i/email.svg'); } }
    &[ext], &.ext { &::before { background-image: url('../img/i/ext.svg'); } }
    &[info], &.info { &::before { background-image: url('../img/i/info.svg'); } }
    &[legal], &.legal { &::before { background-image: url('../img/i/legal.svg'); } }
    &[news], &.news { &::before { background-image: url('../img/i/newsletter.svg'); } }
    &[plus], &.plus { &::before { background-image: url('../img/i/plus.svg'); } }
    &[pod], &[podcast], &.podcast { &::before { background-image: url('../img/i/podcast.svg'); } }
    &[public], &.public { &::before { background-image: url('../img/i/brand/public.svg'); } }
    &[theatre], &[theater], &[drama], &[masks], &.drama { &::before { background-image: url('../img/i/theatre.svg'); } }
    &[warn], &.warn { &::before { background-image: url('../img/i/warn.svg'); } }
    &[qrcode], &.qrcode { &::before { background-image: url('../img/i/qrcode.svg'); } }
}

main input.cta[type="submit"] {
    overflow:visible;
    max-width: unset;
}

main a:not([noicon]) {
    &[href^="http://"], &[href^="https://"] {
        &::before { background-image: url('../img/i/ext.svg'); }
        > i::before { background-image: none; margin: 0; content: none; }
    }

    &[href*="bsky.app"] { &::before { background-image: url('../img/i/brand/bsky.svg'); } }
    &[href*="facebook.com"], &[href*="fb.me"] { &::before { background-image: url('../img/i/brand/facebook.svg'); } }
    &[href*="github.com"], &[href*="git.io"] { &::before { background-image: url('../img/i/brand/github.svg'); } }
    &[href*="instagram.com"], &[href*="instagr.am"] { &::before { background-image: url('../img/i/brand/instagram.svg'); } }
    &[href*="indiegogo.com"] { &::before { background-image: url('../img/i/brand/indiegogo.svg'); } }
    &[href*="itch.io"] { &::before { background-image: url('../img/i/brand/itch-io.svg'); } }
    &[href*="queer.party"] { &::before { background-image: url('../img/i/brand/mastodon.svg'); } }
    &[href*="patreon.com"] { &::before { background-image: url('../img/i/brand/patreon.svg'); } }
    &[href*="paypal.com"] { &::before { background-image: url('../img/i/brand/paypal.svg'); } }
    &[href*="spotify.com"] { &::before { background-image: url('../img/i/brand/spotify.svg'); } }
    &[href*="tiktok.com"] { &::before { background-image: url('../img/i/brand/tiktok.svg'); } }
    &[href*="tumblr.com"] { &::before { background-image: url('../img/i/brand/tumblr.svg'); } }
    &[href*="twitch.tv"] { &::before { background-image: url('../img/i/brand/twitch.svg'); } }
    &[href*="twitter.com"], &[href*="://x.com/"] { &::before { background-image: url('../img/i/brand/twitter.svg'); } }
    &[href*="wordpress.com"] { &::before { background-image: url('../img/i/brand/wordpress.svg'); } }
    &[href*="youtube.com"], &[href*="youtu.be"] { &::before { background-image: url('../img/i/brand/youtube.svg'); } }
    &[href*="redbubble.com"] { &::before { background-image: url('../img/i/brand/redbubble.svg'); }}

    &[href*="backstage.com"] { &::before { background-image: url('../img/i/brand/backstage.svg'); } }
    &[href*="brownpapertickets.com"], &[href*="onthestage.tickets"] { &::before { background-image: url('../img/i/ticket.svg'); } }
    &[href*="carrd.co"] { &::before { background-image: url('../img/i/brand/carrd.svg'); } }
    &[href*="linktr.ee"] { &::before { background-image: url('../img/i/brand/linktree.svg'); } }
    &[href*="linkedin.com"] { &::before { background-image: url('../img/i/brand/linkedin.svg'); } }
    &[href*="watchnebula.com"] { &::before { background-image: url('../img/i/brand/nebula.svg'); } }

    &[href^="mailto:"] { &::before { background-image: url('../img/i/email.svg'); } }
    &[href*="list-manage.com"] { &::before { background-image: url('../img/i/newsletter.svg'); } }
    &[href^="/email-list"] { &::before { background-image: url('../img/i/newsletter.svg'); } }
    &[href*="anchor.fm"], &[href*="broadwaypodcastnetwork.com"], &[href*="buzzsprout.com"], &[href*="insensitivenetwork.com"], &[href*="libsyn.com"], &[href*="podbean.com"], &[href*="podcasts.apple.com"] { &::before { background-image: url('../img/i/podcast.svg'); } }
}
time::before { background-image: url('../img/i/cal.svg'); }

a i:hover, a:hover i { opacity: .8; }
header i:before {
    opacity: 1;
    filter: invert(1);
}
