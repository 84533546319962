html {
    font-family: 'Halant', Palatino, Book Antiqua, serif;
}
header, h1, h2, h3, h4, h5, h6 {
    font-family: 'Cinzel', Georgia, serif;
    font-variant: small-caps;
}

.lg, *[lg], *[biggen], .biggen {
    font-size: 1.2em;
    font-weight: bold;
}

q {
    &::before { content: open-quote; }
    &::after { content: close-quote; }

    &[bare]{
        &::before { content: ''; }
        &::after { content: ''; }
    }
}
small, .sm, *[sm] {
    font-size: 0.8em;
    font-weight: normal;
    font-variant: normal;
}
u, .u, *[u] {
    text-decoration: underline;
}
pre {
    font-family: 'Halant', Palatino, Book Antiqua, serif;
}

code, tt, .mono, *[mono] {
    font-family: 'Noto Sans Mono', monospace;
    font-size: 0.9em;
}
