/* Set defaults for the whole system.
 */
:root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-foreground: #333333;
    --color-background: #D3D3D3;

    --color-primary-glow: #FFF1B2;
    --color-primary-shadow: #3C3100;

    --color-primary-bright: #CEC186;
    --color-primary-dim: #9E9159;
    --color-primary-dark: #6D612D;

    --color-secondary-glow: #9168FF;
    --color-secondary-shadow: #1F003C;

    --color-secondary-bright: #754ECE;
    --color-secondary-dim: #58349E;
    --color-secondary-dark: #3C1A6D;
}

html {
    font-size: 16px; // 1 REM = 16px
}
body {
    background-color: var(--color-background);
    color: var(--color-foreground);
    font-family: inherit;
}
h2, h3, h4, h6 {
    font-weight: 900;
}
h1 {
    font-size: 2em;
    font-weight: 400;
}
h2 {
    font-size: 1.75em;
}
h3 {
    font-size: 1.5em;
}
h4 {
    font-size: 1.25em;
}
h5 {
    font-size: 1.25em;
    font-weight: 400;
}
h6 {
    font-size: 1.125em;
}
a {
    font-weight: 600;
    color: var(--color-primary-dim);
    text-decoration: none;
}
:target, :focus, :focus-visible { // this makes the currently selected element via anchor gently glow.
    text-shadow: 0 0 0.25em var(--color-primary-dark);
    -webkit-animation: dim 1s ease-in-out both;
    -moz-animation: dim 1s ease-in-out both;
    animation: dim 1s ease-in-out both;
}
a {
    &:active, &:hover, &:target, &:focus-within, &:focus, &:focus-visible {
        color: var(--color-primary-bright);
        text-shadow: 0 0 0.25em var(--color-primary-dark);
        -webkit-animation: glow 0.5s ease-in-out both;
        -moz-animation: glow 0.5s ease-in-out both;
        animation: glow 0.5s ease-in-out both;
    }
}
@-webkit-keyframes dim {
    from {
        text-shadow: 0 0 0.25em var(--color-primary-dark);
    }
    to {
        color: var(--color-primary-black);
        text-shadow: 0 0 0.25em var(--color-primary-dim);
    }
}
@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 0.25em var(--color-primary-dark);
    }
    to {
        color: var(--color-primary-glow);
        text-shadow: 0 0 0.25em var(--color-primary-shadow);
    }
}
a:visited {
    color: var(--color-primary-dim);
    text-decoration: none;
}
img {
    max-width: 100%;
}

/* Lists and Definitions */
li.task-list-item {
    list-style-type: none;
    margin-bottom: 0.25em;

    &:last-child {
        margin-bottom: 0;
    }
    input[type="checkbox"][disabled][checked] {
        filter: invert(1) brightness(5);
    }
    input[type="checkbox"][disabled] {
        filter: brightness(5);
        transform: scale(1.25);
    }
    &> input[type=checkbox]:disabled {
        margin-left: -1.25em;
        margin-right: .5em;
    }
}
ul, ol, dl {
    max-width: 50rem;
    text-align: left;
    margin: 0.25em auto;
}
ul li {
    list-style-position: inside;
    list-style: disc;
    padding-left: 0.25em;
}
ol li {
    list-style-position: inside;
    list-style: decimal;
    padding-left: 0.25em;
}
dl dt {
    color: #303030;
}

/* tables */
table {
    width: 100%;
    border: 1px solid #ebebeb;
}
th {
    font-weight: 500
}
td {
    font-weight: 300;
    text-align: center;
    border: 1px solid #ebebeb;
}

/* Other */
hr {
    height: 1px;
    margin-bottom: 1em;
    margin-top: 1em;
    line-height: 1px;
    background: transparent url('../img/hr.png') top center no-repeat;
    border: none;
}
form {
    padding: 1em;
    background: #f2f2f2;
    width: auto;

    &.pure-form {
        select, input[type], select {
            padding: 0.25em 0.5em;
            vertical-align: baseline;
            height: 2em;
        }
        input[type="checkbox"] {
            height: auto;
        }
    }
}
blockquote {
    max-width: 50rem;
    text-align: left;
    margin: 1em auto;
    padding: 0.5rem;
    background-color: #c3c3c3;
    border-left: 0.4rem solid #888;
    border-radius: 0 .5em .5em 0;
}
blockquote p:first-child {
    margin-top: 0
}
blockquote p:last-child {
    margin-bottom: 0;
}
details {
    text-align: left;
    margin: 1em auto;
    padding: .5em;
    max-width: 50rem;
    border: 1px solid rgba(0,0,0,0);
    border-radius: 0 .5em .5em 0;

    &[open] {
        background-color: #c3c3c3;
        border-left: 0.4rem solid #888;
        border-color: #888
    }

    & > summary {
        margin-left: 0;
    }
    & > summary + * {
        margin-top: 0;
    }
    & > * {
        margin-left: 1rem;
    }

    summary {
        font-weight: 600;
        font-size: 1.25em;

        &:focus {
            outline: none;
        }

        small {
            font-weight: 400;
            font-size: .8em;
        }
    }
}

dialog {
    color: var(--color-foreground);
    background: var(--color-white);
    border: 1px var(--color-foreground);
    border-radius: 0.7rem;
    box-shadow: 0 10px 20px var(--color-foreground);
    min-width: 30%;
    max-width: 90%;
    text-align: left;
    max-height: 90vh;
    padding: 1rem;

    header {
        font-size: 2rem;
        line-height: 3rem;
        width: auto;
        padding-right: 3rem;
        position: relative;

        a[close] {
            color: #777;
            position: absolute;
            font-size: 2rem;
            line-height: 3rem;
            right: 0;
            top: 0;
            width: 3rem;
            height: 3rem;
            text-align: center;
        }

        &::after {
            content: '\00A0';
            position: absolute;
            display: block;
            height: 1px;
            line-height: 1px;
            font-size: 1px;
            left: 0;
            width: 100%;
            border-top: 1px solid var(--color-foreground);
            opacity: 0.3;
        }
    }

    ::backdrop {
        transition: opacity 0.2s ease-out;
        background: var(--color-black);
        opacity: 0.8;
    }
}
