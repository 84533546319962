
body {
    width: 100%;
    min-width: 33rem;
    margin: 0 auto;
}
@media screen and (max-width: 800px) {
    body > header nav ul li a {
        padding: 0.75rem 0.5rem;
    }
}
@media screen and (max-width: 650px) {
    html {
        font-size: 12px;
    }
    body > header nav ul li a {
        padding: .75rem .25rem;
    }
}
@media screen and (max-width: 450px) {
    body > header nav ul li a {
        padding: .5rem .2rem;
        font-size: 1rem;
    }
}

hr {
    clear: left;
}

.news {
    display: block;
    position: relative;

    .excerpt {
        text-align: left;
        display: grid;
    }
    .excerpt.noimage {
        text-align: center;
    }
    img.banner {
        object-fit: contain;
        float: left;
        clear: left;
        margin: 0em 1em 0.5em -1em;
        max-height: 14rem;
        width: 30%;
    }
    &.summary-first img.banner {
        float: none;
        margin: .5em;
        width: auto;
        max-width: 70%;
        max-height: 20rem;
    }
}

main {
    margin: 2rem auto;
    padding: 0;
    width: 80%;
    max-width: 60rem;
    text-align: center;

    img {
        max-width: Min(100%, 60rem);
        max-height: 33.75rem;
    }
}

.video-box {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: 0.5em;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

body > header {
    background-color: #000;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    .banner {
        max-width: 100rem;
        margin: 0 auto;
        background: url('../img/banner.jpg') no-repeat 32% 48%;
        background-size: cover;
        overflow: hidden;
        height: 18.75rem;
        text-align: center;
        color: #FFFFFF;

        .wetc_logotext {
            font-size: 7.5vw;
            width: 100%;
            font-style: italic;
            font-weight: bold;
            line-height: 17rem;
            background: linear-gradient(180deg, var(--color-primary-bright) 0 7.5rem, var(--color-primary-glow) 8.25rem 9.0rem, var(--color-primary-bright) 9.75rem);
            background-clip: text;
            -moz-background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            filter: drop-shadow(0 0 .2rem var(--color-foreground)) drop-shadow(0 0 0.4rem var(--color-primary-bright)) drop-shadow(0 -0.5rem 0.8rem var(--color-primary-dim));
        }
        .banner_scroll {
            position: relative;
            bottom: calc(14rem - 10vw);
            width: 35vw;
            max-width: 490px;
            margin: -5rem -17.5vw;
            filter: drop-shadow(0 0 .2rem var(--color-foreground)) drop-shadow(0 0 0.4rem var(--color-primary-bright)) drop-shadow(0 -0.5rem 0.8rem var(--color-primary-dim));
        }
        
        .banner_button {
            position: relative;
            bottom: 3rem;
            margin: 0 auto;
            width: 90%;
            max-width: 60rem;
            display: flex;
            flex-direction: row-reverse;
            filter: drop-shadow(1px 1px 3px #000);

            &> *:hover {
                filter: contrast(150%);
            }
            .donate_btn {
                background-color: #333;
                padding: .6rem 1rem;
                font-size: 1.2rem;
                color: #fff;
                border-radius: 1rem;
            }
        }
    }

    nav {
        background-color: #333;
        padding: 0;
        font-size: 1.2rem;
        margin-left: -4rem;
        margin-right: -4rem;

        ul {
            display: table;
            margin: 0 auto;
            padding: 0.67rem;
            background-color: #333;
            list-style-type: none;
            list-style: none;
            overflow: hidden;
        }

        li {
            display: inline;
            float: left;
        }

        li a {
            display: block;
            color: #fff;
            text-align: center;
            padding: .75rem 1.25rem;
            text-decoration: none;
            font-family: 'Cinzel', serif;
            border-radius: 1rem;
        }
        li a:visited {
            color: #fff;
        }
        /* Change the link color to #111 (black) on hover */
        li a:hover {
            background-color: #111;
            color: #fff;
        }
    }
}
@media screen and (min-width: 1400px) {
    body > header .banner .wetc_logotext {
        font-size: 6.5rem;
    }
    body > header .banner .banner_scroll {
        position: relative;
        font-size: 7.5rem;
        bottom: 5.25rem;
        width: 490px;
    }
}
@media screen and (max-width: 650px) {
    body > header .banner .wetc_logotext {
        font-size: 4.125rem; /* should be 50px */
    }
    body > header .banner .banner_scroll {
        position: relative;
        font-size: 4.125rem;
        bottom: 8rem; /* should be 100px */
        width: 220px; /* should be 220px */
    }
}
@media screen and (min-width: 1200px) {
    body > header .banner .donate_btn {
        right: calc(50vw - 30rem);
    }

}

.columns {
    width: 100%;
    height: 100%;
}

.row {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 49%;
    width: 100%;
}
@media screen and (max-width: 900px) {
    .row {
        flex: 0 0 100%;
        flex-flow: row wrap;
    }
}
@media screen and (min-width: 901px) {
    .row .small-only {
        display: none;
    }
}

.row:nth-of-type(2) {
    display: flex;
    flex-flow: column wrap;
}

.row .col {
    display:table-cell;
    position: relative;
    width: 100%;
    min-height:100%;
    margin: 0 1rem;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
p {
    margin-block-start: 0;
    margin-block-end: 1em;
}
aside+p {
    margin-block-start: 1em;
}
footer {
    margin: 1rem auto;
    padding: 1rem;
    width: 80%;
    background:transparent url('../img/hr.png') top center no-repeat;
    text-align: center;

    a > i {
        font-size: 3rem;
        max-width: 3.25rem !important;

        &::before {
            margin: 0.25rem;
            padding: 0;
            width: 3rem;
            height: 3rem;
        }
    }
}

a:hover {
    .social {
        opacity: .8
    }
}

a {
    display: inline-block;
    break-inside: avoid;
}

.vr-notice {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 2em;
    padding: .5em;
}
.vr-notice-success, .vr-notice-failure {
    position: absolute;
    top: 31.5em;
    left: 50%;
    margin-left: -12.5em;
    width: 24em;
    background-color: var(--color-background);
    border: 1px solid var(--color-primary-shadow);
    border-radius: .75em;
    box-shadow: 0 0 1em var(--color-primary-shadow)
}